import { environment } from '../../../environments/environment';
import { LoaderService } from '../../services/loader.service';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FormService } from 'app/services/form.service';
import { MetaService } from 'app/services/meta.service';
import { Globals } from '../../globals';
import { emailDomainValidator } from 'app/directives/email-domain-validator';
import { AccountService } from 'app/services/account.service';
import { LocalStorageService } from 'app/services/localStorage.service';
import { CognitoService } from 'app/services/cognito.service';

@Component({
  selector: 'app-lead-gen',
  templateUrl: './lead-gen.component.html',
  styleUrls: ['./lead-gen.component.scss'],
})
export class LeadGenComponent implements OnInit, AfterViewInit {
  @ViewChild('confirmation') confirmation: any;
  form: UntypedFormGroup;
  questions: any;
  showFailure = false;
  returnRoute = location.host + '/contact-confirmation';
  formURL: string;
  oid: string;
  campaignID: string;
  recordType: string;
  countryList: any[];
  currentCustomer: any[];
  customerQuries: any[];
  cities: any[];
  emailSantizeError = false;
  reference_Information_c: string;
  emailValue: string;

  constructor(
    public store: Store<any>,
    protected formService: FormService,
    private metaService: MetaService,
    public loaderService: LoaderService,
    private accountService: AccountService,
    private localStorageService: LocalStorageService,
    private cognitoService: CognitoService,
  ) {}

  ngOnInit() {
    this.loaderService.loadingContent.emit(true);
    window.scrollTo({ top: 0 });
    this.formURL = environment.sfLeadSubmissionURL;
    this.oid = environment.sfOrgId;
    this.campaignID = environment.sfCampaignID;
    this.recordType = environment.sfRecordType;
    this.accountService.pendoVisitorId.subscribe((vid) => {
      this.reference_Information_c = vid;
    });
  }

  ngAfterViewInit(): void {
    this.emailValue = (this.cognitoService.getUserInfo()) ? this.localStorageService.getItem('userEmail') : '';
    this.formService.getForm('lead-gen').subscribe((form) => {
      if (form.Items && form.Items.length > 0) {
        if (form.Items[0].questions) {
          this.countryList = form.Items[0].countries;
          this.questions = form.Items[0].questions;
          this.currentCustomer = form.Items[0].currentCustomer;
          this.customerQuries = form.Items[0].customerQuries;
          this.form = this.toFormGroup(this.questions);
          // this.form.controls.country_code.setValue("");
          // this.form.controls.state_code.setValue("");

          this.loaderService.loadingContent.emit(false);
        }
      }
    });
  }

  toFormGroup(questions) {
    const group: any = {};
    questions.forEach((question) => {
      if (question.key === 'email') {
        group[question.key] = new UntypedFormControl(
          this.emailValue || '',
          Validators.compose([
            Validators.required,
            Validators.pattern(Globals.emailValidationRegEx),
            emailDomainValidator(),
          ])
        );
      } else if (question.key === 'url') {
        group[question.key] = new UntypedFormControl(
          '',
          Validators.compose([Validators.required, Validators.pattern('^.+\\..+$')])
        );
      } else if (question.key === 'phone') {
        group[question.key] = new UntypedFormControl('', Validators.compose([Validators.pattern(/^\+?[\d]{6,16}$/)]));
      } else if (question.key === 'Description_Hidden__c') {
        group[question.key] = new UntypedFormControl(
          '',
          Validators.compose([Validators.pattern(/^[a-zA-Z0-9., ]+$/)])
        );
      } else {
        group[question.key] = question.required
          ? new UntypedFormControl(question.value || '', [
              Validators.required,
              this.formService.noWhitespaceValidator,
              this.formService.noInvalidNameValidator,
            ])
          : new UntypedFormControl(question.value || '');
      }
    });

    group['country_code'] = new UntypedFormControl('', Validators.required);
    group['state_code'] = new UntypedFormControl('', Validators.required);
    group['Config_Field1__c'] = new UntypedFormControl('', Validators.required);
    group['Product_Services_Interest__c'] = new UntypedFormControl('', Validators.required);

    return new UntypedFormGroup(group);
  }

  // // show all field errors on submit
  touchForm() {
    this.formService.markFormGroupTouched(this.form);
  }

  // private markFormGroupTouched(formGroup: FormGroup) {
  //   (<any>Object).values(formGroup.controls).forEach(control => {
  //     control.markAsTouched();

  //     if (control.controls) {
  //       this.markFormGroupTouched(control);
  //     }
  //   });
  // }

  // swap out state/province/region list based on country
  private changeCountry(count) {
    if (this.countryList.find((con) => con.value == count)) {
      this.cities = this.countryList.find((con) => con.value == count).cities;
    } else {
      this.cities = [];
    }
    this.form.controls.state_code.setValue('');
    this.form.controls['country_code'].setValue(count);
  }

  private changeState(state) {
    this.form.controls['state_code'].setValue(state);
  }

  private changeCurrentCustomer(customer) {
    this.form.controls['Config_Field1__c'].setValue(customer);
  }

  private changeCustomerQuries(query) {
    this.form.controls['Product_Services_Interest__c'].setValue(query);
  }

  // public noWhitespaceValidator(control: FormControl) {
  //   const isWhitespace = (control.value || '').trim().length === 0;
  //   const isValid = !isWhitespace;
  //   return isValid ? null : { 'whitespace': true };
  // }
}
