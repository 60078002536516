import { CognitoService } from './cognito.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ApiCache } from '../types/api-cache';
import jwt_decode from 'jwt-decode';

let defaultHeaders = {} as any;

defaultHeaders = {
  'Content-Type': 'application/json',
  'x-api-key': environment.apiKey,
  Authorization: 'p',
};

@Injectable()
export class ApiService {
  private apiUrl = environment.apiHost;
  private selfcertApiUrl = environment.selfcertApiHost;
  private cache: ApiCache[] = [];
  preventCache = ['/Token', '/User', '/selfcert'];

  constructor(private http: HttpClient, private cognitoService: CognitoService) {}

  get(
    path: string,
    params = {},
    token: string | boolean = false,
    requestHeaders?: any,
    ignoreCache?: boolean
  ): Observable<any> {
    return new Observable((observer) => {
      const endpoint = this.objectToQueryString(params) ? `${path}?${this.objectToQueryString(params)}` : `${path}`;
      const cacheEntry = ignoreCache === true ? null : this.getCacheEntry(endpoint);
      const targetApiUrl = path.startsWith('/selfcert') ? this.selfcertApiUrl : this.apiUrl;

      if (cacheEntry) {
        // Data already exists, so set timeout for loader/spinner
        setTimeout(() => {
          observer.next(cacheEntry.data);
          observer.complete();
        }, 200);
      } else {
        const headers = {
          ...defaultHeaders,
          ...requestHeaders,
        };

        if (this.cognitoService.getUserInfo() !== null) {
          if (path.startsWith('/selfcert')) {
            headers['Authorization'] = `Bearer ${this.cognitoService.getJWT()}`;
          } else {
            headers['Authorization'] = this.cognitoService.getJWT();
          }
        } else {
          headers['Authorization'] = 'p';
        }

        this.checkJWT(headers['Authorization']);

        if (token) {
          headers['x-auth-token'] = token;
        }

        let httpHeaders = new HttpHeaders();
        for (const x in headers) {
          httpHeaders = httpHeaders.append(x, headers[x].toString());
        }

        this.http.get<any>(targetApiUrl + endpoint, { headers: httpHeaders }).subscribe(
          (data) => {
            if (!this.preventCache.find((string) => endpoint.includes(string))) {
              this.addCacheEntry(endpoint, data);
            }

            observer.next(data);
            observer.complete();
          },
          (error) => {
            observer.error(error);
            observer.complete();
          }
        );
      }
    });
  }

  post(path: string, data: any): Observable<any> {
    const headers = defaultHeaders;
    const targetApiUrl = path.startsWith('/selfcert') ? this.selfcertApiUrl : this.apiUrl;
    const httpOptions = {
      headers: new HttpHeaders(headers),
    };

    if (this.cognitoService.getUserInfo() !== null) {
      if (path.startsWith('/selfcert')) {
        headers['Authorization'] = `Bearer ${this.cognitoService.getJWT()}`;
      } else {
        headers['Authorization'] = this.cognitoService.getJWT();
      }
    }

    this.checkJWT(defaultHeaders['Authorization']);

    return new Observable((observer) => {
      this.http.post<any>(targetApiUrl + path, data, httpOptions).subscribe(
        (resData) => {
          observer.next(resData);
          observer.complete();
        },
        (error) => {
          observer.next(error);
          observer.complete();
        }
      );
    });
  }

  delete(path: string): Observable<any> {
    const headers = defaultHeaders;

    const httpOptions = {
      headers: new HttpHeaders(headers),
    };

    if (this.cognitoService.getUserInfo() !== null) {
      headers['Authorization'] = this.cognitoService.getJWT();
    }

    this.checkJWT(this.cognitoService.getJWT());

    return new Observable((observer) => {
      this.http.delete<any>(this.apiUrl + path, httpOptions).subscribe(
        (resData) => {
          observer.next(resData);
          observer.complete();
        },
        (error) => {
          observer.next(error);
          observer.complete();
        }
      );
    });
  }

  addCacheEntry(path: string, data: any) {
    const cacheEntry = this.getCacheEntry(path);
    if (!cacheEntry) {
      this.cache.push({
        path,
        data,
      });
    }
  }

  getCacheEntry(path) {
    return this.cache.filter((item) => item.path === path).pop();
  }

  objectToQueryString(obj) {
    const query = [];

    for (const key in obj) {
      if (key && obj[key]) {
        query.push(`${key}=${obj[key]}`);
      }
    }

    if (!query.length) {
      return null;
    }

    return query.join('&');
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private checkJWT(token: string) {
    if (token !== 'p') {
      const decoded: any = jwt_decode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      if (decoded) {
        if (decoded.exp - currentTime <= 0) {
          this.cognitoService.logout();
        }
      }
    }
  }
}
