<div class="-wrapper">
  <div class="-container-top">

  </div>
  <div class="-container">
    <div class="-main">
      <div class="-head">
          <h1>{{filterTitle}}</h1>
          <p>Look through our APIs to find the right solution for you. Use the filter options to refine your search further.</p>
      </div>
      <div class="-content">
        <div class="-left-content" *ngIf="!!capabilityCount.length">
          <api-left-menu
            [showCounts]="false"
            [capabilityFilter]="capabilityCount"
            [menuActive]="selectedFilters.length === 0 ? 'All' : (selectedFilters.indexOf('All') > -1) ? 'All' : null"
            (leftFilterChange)="applyCapabilityFilter($event)">
          </api-left-menu>
        </div>
        <div class="-right-content">
          <notification class="-colorAccentLightBlue -solutionFinderLink" noTitle="true">
            <p>Not sure which API to use? Try our <b> <app-link [to]="'/solution-finder'">Solution Finder!</app-link></b></p>
          </notification>
          <div class="-sort-filter">
            <div class="-multi-filter">
              <ng-container *ngTemplateOutlet="businesstype"></ng-container>
              <ng-container *ngTemplateOutlet="regionavailability"></ng-container>
            </div>
            <div class="-multi-filter-button" (click)="showFilters()">Filters</div>
            <div class="-sort">
              <dropdown
                size="lg"
                buttonTitle="Sort by: "
                [dropdownContent]="sortOptions"
                type = "sort"
                (optionSelected)="sortProducts($event)">
              </dropdown>
            </div>
            <ng-container *ngTemplateOutlet="filterModal"></ng-container>
          </div>
          <div class="-filter-results">
            <div class="-results-info">
              <ng-container *ngTemplateOutlet="resultinfoMessage"></ng-container>
              <ng-container *ngFor="let selectedFilter of selectedFilters">
                <ng-container *ngIf="selectedFilter !== 'All'">
                  <ng-container *ngTemplateOutlet="pillFilter; context: {filter: selectedFilter}"></ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="selectedFilters && selectedFilters.length && selectedFilters[0] !== 'All'" >
                <ng-container *ngTemplateOutlet="clearall"></ng-container>
              </ng-container>
            </div>
            <div class="-results">
              <div class="-results-container" *ngIf="!noResults">
                <card-api-convergence 
                  *ngFor="let product of paginatedProductsList"
                  [family]="product.familyName"
                  [title]="product.productTitle" 
                  [externalTo]="product.external ? product.external : false"
                  [to]="product.productTo ? product.productTo : undefined">
                  <p>{{product.productDescription}}</p>
                </card-api-convergence>
              </div>
              <div class="-no-results-container" *ngIf="noResults">
                <p>No results found</p>
                <p>Try adjusting your filters to find what you’re looking for</p>
              </div>
            </div>
            <div *ngIf="products && products.length > pagerActivationThreshold && paginationActive">
              <api-pagination 
                [productListSize]="products.length" 
                [resultsPerPage]="resultsPerPage" 
                [paginationMaxItems]="maxPagerItemsVisible"
                [pageRefresher]="pageRefresher"
                (updatePageSelection)="getPaginationIndexes($event)">
              </api-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #pillFilter let-filter="filter">
  <pill
    [typeFilter]="true"
    class="-colorAccentLightBlue" 
    [value]="filter"
    (filterRemoved)="removeFilter($event)">
    {{filter}}
  </pill>
</ng-template>

<ng-template #resultinfoMessage>
  <p>{{products?.length}} <span *ngIf="products && (products.length === 0 || products.length === 1)">result </span> <span *ngIf="products && products.length > 1">results </span><span *ngIf="selectedFilters && selectedFilters.length && selectedFilters[0] !== 'All'">filtered by</span></p>
</ng-template>

<ng-template #clearall>
  <span class="-clear-results" (click)="reset()">
    <button-icon iconattributes="-sm15" icon="trash"></button-icon><span>Clear all</span>
  </span>
</ng-template>

<ng-template #businesstype>
  <multi-select-dropdown #multiFilters
    size="lg"
    buttonTitle="Business type"
    [dropdownContent]="businessTypeCount"
    [showCounts]="isCountEnabled"
    (optionSelected)="applyMultiFilter($event)">
  </multi-select-dropdown>
</ng-template>

<ng-template #regionavailability>
  <multi-select-dropdown #multiFilters
    size="lg"
    buttonTitle="Regional availability"
    [dropdownContent]="regionCount"
    [showCounts]="isCountEnabled"
    (optionSelected)="applyMultiFilter($event)">
  </multi-select-dropdown>
</ng-template>

<ng-template #filterModal>
  <small-screen-modal class="-multi-filter-modal" #multiFilterModal>
    <div class="-filter-head">
      <button-icon 
        icon="close"
        iconattributes="-xsm" 
        iconhovercolor="-colorSupportingWhite"
        iconcolor="-colorSupportingWhite"
        (click)="closeFilter()">
      </button-icon>
      <span class="-modal-title-text">Filter Products</span>
      <ng-container *ngTemplateOutlet="clearall"></ng-container>
    </div>
    <div class="-filter-body">
      <div class="-results-info">
        <ng-container *ngIf="selectedFilters && selectedFilters.length > 0" >
          <ng-container *ngTemplateOutlet="resultinfoMessage"></ng-container>
        </ng-container>
        <ng-container *ngFor="let selectedFilter of selectedFilters">
          <ng-container *ngIf="selectedFilter !== 'All'">
            <ng-container *ngTemplateOutlet="pillFilter; context: {filter: selectedFilter}"></ng-container>
          </ng-container>
        </ng-container>
      </div>
      <ng-container *ngTemplateOutlet="businesstype"></ng-container>
      <ng-container *ngTemplateOutlet="regionavailability"></ng-container>
    </div>
    <div class="-filter-foot">
      <wpbutton (click)="closeFilter()">View {{products?.length}}&nbsp;<span *ngIf="products && (products.length === 0 || products.length === 1)">Result</span><span *ngIf="products && products.length > 1">Results</span></wpbutton>
    </div>
  </small-screen-modal>
</ng-template>