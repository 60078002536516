import { Component, OnInit, Input } from '@angular/core';
import { MenuService } from '../../services/menu.service';
import { Globals } from '../../globals';
import { MetaService } from 'app/services/meta.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  @Input() mode: string;
  private menuId = 'footer-convergence';
  menu = [];
  private socialMenuId = 'footer_social';
  socialMenu: any;
  date: any;
  metadata = null;

  constructor(private menuService: MenuService, public globals: Globals, private metaService: MetaService) {
    this.mode = 'primary';
  }

  ngOnInit() {
    this.date = new Date().getFullYear();
    this.menuService.getMenu(this.menuId).subscribe((menu) => {
      if (menu.Items && menu.Items.length > 0) {
        this.menu = menu.Items[0].items;
      }
    });

    this.menuService.getMenu(this.socialMenuId).subscribe((menu) => {
      if (menu.Items && menu.Items.length > 0) {
        this.socialMenu = menu.Items[0].items;
      }
    });

    this.metaService.metadata.subscribe((meta) => {
      this.metadata = meta;
    });
  }
}
