<div [@showHide]="isNotificationReady ? 'show' : 'hide'">
  <notification 
    [class]="notification.status === 'failure' ? '-colorAccentPink' : '-colorAccentLightBlue'"
    [title]="notification.title" 
    [showDismissBtn]="true"
    (dismissNotification)="unsetNotification($event)"
    *ngIf="notification && notification.show">
    {{ notification.description }}
  </notification>
</div>
<div class="-docpage-content">
  <div class="-left-pane" [class]="!isDocIndex ? '-full-width' : '-pane-width'">
    <content-viewer
    *ngIf="body" 
    [content]="body" 
    (docRendered)="handleDocRendered($event)">
  </content-viewer>
  </div>
  <div class="-right-pane" *ngIf="isDocIndex">
    <doc-index [indexContainer]="'content-viewer'" [title]="'Within this Page'" *ngIf="docIndexReady"></doc-index>  
  </div>
</div>

<div *ngIf="requestNowConfirmModal">
  <modal 
      [visible]="true" 
      (visibleChange)="closeConfirmModal()" 
      [secondaryModal]="true"
      [title]="'Not logged in !'">
      <div>
      <p class="-fontSmall -colorAccentGrey -description-padding">
         You must login to access this feature
      </p>
      <div>
        <wpbutton type="submit" to="/account/login">Login</wpbutton>
        <wpbutton type="submit" to="/account/register">Create a free account</wpbutton>
      </div>
      </div>
  </modal>
</div>