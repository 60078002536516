<div class="no-close-2">
<h3 *ngIf="navTitle" >{{ navTitle }}</h3>
<ul *ngFor="let item of navItems; index as itemIndex" class="nav-vertical -menu main-menu-sidebar no-close-2">
    <li class="nav-vertical -menu -item" >
      <div class="nav-vertical -menu -item -container d-flex justify-content-between"  (click)="toggleChild('p', item.uniqueId)"
        [ngClass]="{ '-no-child': !item.children, '-active': item.active, '-open': (item.children && !_closedStates['p'][item.uniqueId]['state'])}">
  
        <app-link 
          class="nav-vertical -menu -item -link"
          (click)="toggleChild('p', item.uniqueId, $event)"
          [ngClass]="{ '-active': item.active }" 
          [to]="item.path"
          [disableRoute]="item.path ? false : true"
          [externaloverride]="item.newTab">
          {{ item.title }}
        </app-link>
  
        <button-icon 
          *ngIf="item.children" 
          iconattributes="-xsm"
          [icon]="_closedStates['p'][item.uniqueId]['icon']">
        </button-icon>
  
        <app-link
          *ngIf="item.newTab"
          [to]="item.path"
          [externaloverride]="item.newTab">
          <button-icon
            iconattributes="-sm15"
            (click)="toggleChild('p', item.uniqueId)"
            icon="external-link">
          </button-icon>
        </app-link>
      </div>
  
      <ul *ngIf="item.children" [@_closed]="_closedStates['p'][item.uniqueId]['state']"
        class="nav-vertical -menuChild inner-content-bg">
        <ng-container *ngFor="let child of item.children; index as childIndex">
          <li class="nav-vertical -menuChild -item" >
            <div class="nav-vertical -menuChild -item -container d-flex align-items-start"
              [ngClass]="{ '-hasIcon': child.children, '-active': child.active, '-hasNoIcon': !child.children }">
              <button-icon 
                *ngIf="child.children"
                (click)="toggleChild('c', child.uniqueId)"
                [icon]="_closedStates['c'][child.uniqueId]['icon']">
              </button-icon>
  
              <app-link
                *ngIf="child.children" 
                (click)="toggleChild('c', child.uniqueId)"
                class="nav-vertical -menuChild -item -link"
                [ngClass]="{ '-active': child.active }"
                [to]="child.path"
                [disableRoute]="child.path ? false : true">
                {{ child.title }}
              </app-link>

              <app-link
                *ngIf="!child.children" 
                class="nav-vertical -menuChild -item -link"
                [ngClass]="{ '-active': child.active }"
                [to]="child.path"
                [disableRoute]="child.path ? false : true">
                {{ child.title }}
              </app-link>
            </div>
            <ul class="nav-vertical -menuGrandchild"
              *ngIf="child.children" 
              [@_closed]="_closedStates['c'][child.uniqueId]['state']">
              <ng-container *ngFor="let grandchild of child.children,  index as grandChildIndex">
                <li class="nav-vertical -menuGrandchild -item">
                  <app-link
                    class="nav-vertical -menuGrandchild -item -link"
                    [ngClass]="{ '-active': grandchild.active }" 
                    [to]="grandchild.path"
                    [disableRoute]="grandchild.path ? false : true">
                    {{ grandchild.title }}
                  </app-link>
                </li>
              </ng-container>
            </ul>
          </li>
        </ng-container>
      </ul>
    </li>
  </ul>
</div>