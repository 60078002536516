<!-- <div class="-sdk-parent" #parent></div> -->
<div #parent style="position: relative; top: 70px; min-height: 100vh;">
    <!-- <div class="portal-header">
        <div class="branding-container">
            <div class="logo">
                <p>APIMatic</p>
            </div>
        </div>
    </div> -->
    <div class="hosted-api-docs">
        <div id="apimatic-widget" style="height: 100%; width: 100%;"></div>
    </div>
</div>

