<div class="social-network-share">
  <div class="social-network-share -share-button" clickOutside (clickOutside)="clickedOutside()">
    <icon class="-xmd -colorPrimaryBlack" icon="node" (click)="togglePallete()"></icon>
    <div class="social-network-share -share-pallete" *ngIf="openPallete">
      <div *ngFor = "let mediaType of sharePlatforms" class="social-media-type" [ngClass]="(mediaType === 'twitter' ? '-social-icon -twitter-icon' : '' 
      || mediaType === 'email' ? '-social-icon -mail-icon' : '')">
        <a (click)="openExternalLink(mediaType)">
          <icon [icon] = "mediaType" class="-xmd -colorSupportingWhite"></icon>
        </a>
      </div>
    </div>
  </div>
</div>