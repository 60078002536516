import { Injectable } from '@angular/core';
@Injectable()
export class CopyService {
  copyText(text: string) {
    const copyDummy = document.createElement('textarea');
    copyDummy.setAttribute('id', 'copy-text');
    document.body.appendChild(copyDummy);
    copyDummy.value = text;
    copyDummy.select();
    try {
      document.execCommand('copy');
      document.body.removeChild(copyDummy);
      return true;
    } catch (err) {
      // console.log(err);
    }
  }
}
