import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class RequestNowService {
  constructor(private apiService: ApiService) { }
  requestnowConfirmationEvent = new EventEmitter<string>();
  sendMail(): Observable<string> {
    return new Observable((observer) => {
      this.apiService.post('/api/email', null).subscribe(
        (resp) => {
          observer.next(resp);
          observer.complete();
        },
        (error) => {
          observer.next(error);
          observer.complete();
        }
      );
    });
  }

  startRequestnowConfirmation() {
    this.requestnowConfirmationEvent.emit();
  }
}
