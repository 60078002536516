<div [ngClass]="widthClass">
  <div class="pill-holder -container" [@_closed]="_closed" #pills>
    <ng-content></ng-content>
  
    <div
      class="pill-holder pill-count-style -linkContainer"
      [ngClass]="_closed ? '' : '-open'">
      <icon
        (click)="changeExpandState()"
        [icon]="_iconPath"
        [count]="_iconDisplay"
        class="pill-holder -linkCollapse"
        [ngClass]="
          _closed
            ? '-colorSupportingWhite -textSupportingWhite -md'
            : '-colorSupportingBlack -round -backgroundSupportingWhite -open'
        ">
      </icon>
    </div>
  </div>
</div>
